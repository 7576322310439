%app-dashboard {
  color: #999;
}

.ups-count {
  @extend %app-dashboard;
  font-weight: bold;
}

.ups-count-label {
  @extend %app-dashboard;
  font-size: 0.85rem;
  font-weight: bold;
}

@import 'globals.scss' ;

.ups-number{
  display: block;
  left: 0;
  position: absolute;
  top: -17px;
  width: 100%;
  text-align: center;
}

.ups-number span{
  background: $offBlack;
  border-radius: 17px;
  box-shadow: 0 2px 3px rgba(0,0,0,.2);
  color: $pureWhite;
  display: inline-block;
  font-weight: bold;
  line-height: 34px;
  width: 34px;
}

// Action boxes
.RightArrow:before {
  content: "\f054";
  font-family: "Font Awesome 5 Free", serif;
  font-weight: 900;
  position: absolute;
  left: -13px;
  top: 100px;
  color: $lightColor;
  z-index: auto;
}

.ActionBox {
  position: relative;
  border-width: 1px;
  border-color: $borderGrey;
  border-style: solid;
  background-color: $offWhite;
  box-shadow: 5px 5px 8px $shadowBlur;
}

.ActionBox-content {
  text-align: center;
  width: 100%;
}

.ActionBox-content i {
  margin-bottom: 15px;
  text-align: center;
}

.CardHead {
  margin-top: 30px;
}

.muted {
  color: $lightColor;
  margin: 0;
}

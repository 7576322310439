.variantFormButtons {
  margin-right: 10px;
}

.radioList {
  margin-top: 20px;
  margin-bottom: 20px;
}

.radioBtn {
  margin-bottom: 10px;
}

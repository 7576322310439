$lightColor: #999;
$pureWhite: #eee;
$aerogearBlue: #2497DA;


//card css
$offWhite: #f5f5f5;
$shadowBlur: #aaaaaa;
$borderGrey: #DCDCDC;
$offBlack: #262626;
